// Components
// Utils
import { YoodliExternalPath } from "lib-frontend/utils/paths";
import { LandingPageExternalPath } from "lib-fullstack/utils/paths";
import { LandingPageInternalPath } from "utils/paths";
export var ForBusinessNavData = {
    title: "For Business",
    url: LandingPageInternalPath.FOR_BUSINESS_SALES,
    width: "85px",
    items: [
        {
            title: "GTM Enablement",
            url: LandingPageInternalPath.FOR_BUSINESS_SALES,
            external: false
        },
        {
            title: "Learning & Development",
            url: LandingPageInternalPath.FOR_BUSINESS_LEARNING_AND_DEVELOPMENT,
            external: false
        },
        {
            title: "Partner Enablement",
            url: LandingPageInternalPath.FOR_BUSINESS_CUSTOMER_SUCCESS,
            external: false
        },
        {
            title: "Corporate Communications",
            url: LandingPageInternalPath.FOR_BUSINESS_CORPORATE_COMMUNICATIONS,
            external: false
        },
        // Footer only nav links
        {
            title: "Leadership",
            url: LandingPageInternalPath.FOR_BUSINESS_LEADERSHIP,
            external: false,
            footerOnly: true
        },
        {
            title: "Engineering",
            url: LandingPageInternalPath.FOR_BUSINESS_ENGINEERING,
            external: false,
            footerOnly: true
        }
    ]
};
export var UseCasesNavData = {
    title: "Use Cases",
    url: LandingPageInternalPath.USE_CASES_CRUCIAL_CONVERSATIONS,
    width: "70px",
    items: [
        {
            title: "Conversation Roleplays",
            url: LandingPageInternalPath.USE_CASES_CRUCIAL_CONVERSATIONS,
            external: false
        },
        {
            title: "Interview Preparation",
            url: LandingPageInternalPath.USE_CASES_INTERVIEW_PREPARATION,
            external: false
        },
        {
            title: "Presentation Practice",
            url: LandingPageInternalPath.USE_CASES_PUBLIC_SPEAKING,
            external: false
        },
        {
            title: "Online Meetings",
            url: LandingPageInternalPath.USE_CASES_ONLINE_MEETINGS,
            external: false
        },
        // Footer only nav links
        {
            title: "Toastmasters",
            url: LandingPageInternalPath.USE_CASES_TOASTMASTERS,
            external: false,
            footerOnly: true
        },
        {
            title: "Speech Coaches",
            url: LandingPageInternalPath.USE_CASES_SPEECH_COACHES,
            external: false,
            footerOnly: true
        }
    ]
};
export var ResourcesNavData = {
    title: "Resources",
    url: LandingPageExternalPath.BLOG,
    width: "72px",
    items: [
        {
            title: "Blog",
            url: LandingPageExternalPath.BLOG,
            external: false
        },
        {
            title: "Our Partnerships",
            url: LandingPageInternalPath.OUR_PARTNERSHIPS,
            external: false
        },
        {
            title: "Press",
            url: LandingPageInternalPath.PRESS,
            external: false
        },
        {
            title: "Help Center",
            url: YoodliExternalPath.SUPPORT_PAGE,
            external: true
        },
        {
            title: "Sample Speeches",
            url: LandingPageInternalPath.SAMPLE_SPEECHES,
            external: false
        },
        {
            title: "Community",
            url: LandingPageInternalPath.COMMUNITY,
            external: true
        },
        {
            title: "Trust Center",
            url: YoodliExternalPath.TRUST_CENTER,
            external: true
        },
        // footer only links
        {
            title: "Ambassadors",
            url: LandingPageInternalPath.AMBASSADORS,
            footerOnly: true,
            external: false
        },
        {
            title: "Courses",
            url: LandingPageExternalPath.COURSES,
            footerOnly: true,
            external: false
        },
        {
            title: "Video Tutorials",
            url: "https://www.youtube.com/@yoodli/playlists",
            external: true,
            footerOnly: true
        }
    ]
};
export var AboutNavData = {
    title: "About",
    url: LandingPageExternalPath.ABOUT,
    width: "43px",
    items: [
        {
            title: "Our Team",
            url: LandingPageExternalPath.ABOUT,
            external: false
        },
        {
            title: "Careers",
            url: "https://yoodli-careers.notion.site/yoodli-careers/Careers-at-Yoodli-36d8559419ca48808300b742a9d5efdf",
            external: true
        },
        {
            title: "AI Roleplays",
            url: "".concat(LandingPageExternalPath.BLOG, "/yoodli-ai-roleplays"),
            external: false
        },
        {
            title: "FAQ",
            url: LandingPageInternalPath.FAQ,
            external: false
        },
        {
            title: "Webinars",
            url: LandingPageInternalPath.WEBINARS,
            external: false,
            footerOnly: true
        },
        {
            title: "Status",
            url: YoodliExternalPath.STATUS_PAGE,
            footerOnly: true,
            external: true
        },
        {
            title: "Feature Announcements",
            url: LandingPageInternalPath.FEATURE_ANNOUNCEMENTS,
            external: false,
            footerOnly: true
        },
        {
            title: "Coach Directory",
            url: LandingPageInternalPath.COACH_DIRECTORY,
            footerOnly: true,
            external: false
        },
        {
            title: "Glossary",
            url: LandingPageInternalPath.GLOSSARY,
            external: false,
            footerOnly: true
        }
    ]
};
export var PricingNavData = {
    title: "Pricing",
    url: LandingPageExternalPath.PRICING,
    width: "54px",
    items: []
};
