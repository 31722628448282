// Shared configs
/**
 * Hide a DOM element's text data from being recorded in a Logrocket session.
 * https://docs.logrocket.com/reference/dom#disable-recording-on-elements-with-specific-attributes
 * @example
 * <div data-logrocket-hidden>Hidden text</div>
 */ function sanitizeLogrocketUrl(url) {
    var sanitizedUrl = url;
    // hide signed url signatures
    sanitizedUrl = sanitizedUrl.replace(/x-goog-signature=([^&]*)/gi, "x-goog-signature=redacted");
    sanitizedUrl = sanitizedUrl.replace(/upload_id=([^&]*)/gi, "upload_id=redacted");
    return sanitizedUrl;
}
export var logrocketDomConfig = {
    dom: {
        textSanitizer: true,
        inputSanitizer: true,
        privateAttributeBlocklist: [
            "data-logrocket-hidden"
        ]
    }
};
export var logrocketBrowserConfig = {
    browser: {
        urlSanitizer: function(url) {
            return sanitizeLogrocketUrl(url);
        }
    }
};
/**
 * Configure LogRocket network capture.
 */ export var logRocketNetworkCaptureConfig = {
    // Do not capture client IP address.
    // https://docs.logrocket.com/reference/react-native-disable-ip-capture
    shouldCaptureIP: false,
    // Do not capture request or response body.
    // https://docs.logrocket.com/reference/react-native-network
    network: {
        /* eslint-disable @typescript-eslint/explicit-module-boundary-types */ requestSanitizer: function(request) {
            request.headers = null;
            request.body = null;
            request.url = sanitizeLogrocketUrl(request.url);
            return request;
        },
        responseSanitizer: function(response) {
            response.body = null;
            return response;
        }
    }
};
