import { FALLBACK_SUBDOMAIN } from "../utils/constants";
import { inBrowser, inElectronRenderer } from "../globalEnv";
/**
 * Determines the active `subdomain` and (optional) `prSlug` from the full
 * hostname. If there is no match, it defaults to FALLBACK_SUBDOMAIN (i.e, "app").
 * See test file for examples.
 */ export function getSubdomainFromHostname(hostname) {
    var prSlug = null;
    hostname = hostname !== null && hostname !== void 0 ? hostname : inBrowser() ? window.location.hostname : "";
    hostname = hostname.toLowerCase();
    var match = hostname.match(/^([^.]*)\.([^.]*)\.?yoodli\.ai$|^localhost(?::\d+)?$/);
    var _match_;
    var subdomain = (_match_ = match === null || match === void 0 ? void 0 : match[1]) !== null && _match_ !== void 0 ? _match_ : FALLBACK_SUBDOMAIN;
    if (!match && hostname !== "yoodli.ai") {
        console.log("getSubdomainFromHostname: no match on ".concat(hostname));
        return {
            subdomain: subdomain,
            prSlug: prSlug
        };
    }
    if ([
        "dev",
        "staging"
    ].includes(subdomain)) {
        subdomain = FALLBACK_SUBDOMAIN;
    }
    // If we are working with local HTTPS server, it always assumes fallback sub domain.
    if (!inElectronRenderer() && hostname === process.env.LOCAL_HTTPS) {
        subdomain = FALLBACK_SUBDOMAIN;
    }
    if (hostname.endsWith(".pr.yoodli.ai")) {
        // PR subdomains are in the format `<prSlug>_<subdomain>.pr.yoodli.ai`
        // where prSlug may include underscores, but subdomain may not.
        var split = subdomain.split("_");
        if (split.length === 1) {
            throw new Error("".concat(hostname, " does not match an expected PR site format"));
        } else {
            prSlug = split.slice(0, -1).join("_");
            subdomain = split[split.length - 1];
        }
    }
    return {
        subdomain: subdomain,
        prSlug: prSlug
    };
}
