/**
 * This file is intended to be included by both frontend and backend code and
 * provide configurations and related util functions.
 * However, frontend and backend may run different environments,
 * e.g. frontend runs locally but backend runs on GCP (development),
 * it is rather recommended to use lib-fullstack/client_env.ts for frontend
 * and lib-backend/utils/backendEnv.ts for backend.
 * Note that we should eventually push out client-env.ts to lib-frontend.
 */ /**
 * Determine if it runs in browser context (both web client and Electron rendering process)
 */ export function inBrowser() {
    return typeof window !== "undefined";
}
/**
 * Determine if it runs in Electron renderer process.
 * Note: access to process.env by frontend code should be excluded by this for Electron
 * because process.env is injected only to the web client.
 */ export function inElectronRenderer() {
    // eslint-disable-next-line no-prototype-builtins
    return globalThis.hasOwnProperty("inElectronRenderer");
}
/**
 * Determine if it's in iframe. If something unexpected happens, it returns false.
 */ export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        console.warn("inIframe: unexpected ".concat(e));
        return false;
    }
}
