function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import chroma from "chroma-js";
// Utils
import { fromJS } from "immutable";
// Yoodli Design System Colors
var Y_COLORS = {
    primary: "#524FFC",
    primaryLight: "#F5F8FE",
    primaryHover: "#4E4CBB",
    primaryBackgroundHover: "#f7f8ff",
    secondary: "#1DA9E6",
    secondaryLight: "#E8F6FC",
    secondaryLightest: "#F5FDFF",
    secondaryHover: "#0884BA",
    purple1: "#E6E5FF",
    purple2: "#9B99FF",
    purple3: "#040348",
    purple3Hover: "#07057E",
    // Chart colors for Dashboard
    blueLight: "#76BDFF",
    chartPurple: "#886EFF",
    chartPurpleLight: "#bf92ff",
    chartGreen: "#71D481",
    chartGreenLight: "#9bd59e",
    chartBlue: "#66A4DE",
    blobGreen: "#00ff80",
    programChart: {
        blue: "#E8F6FC",
        blueDark: "#bde5f6",
        purple: "#B7B7E7",
        purpleDark: "#7979d2"
    },
    teal: "#26A69A",
    lightBlue: "#e2edfb",
    gradient: {
        default: "linear-gradient(90deg, #1DA9E6 0%, #6966FE 82%)",
        defaultTransparent: "linear-gradient(90deg, #1DA9E690 0%, #6966FE90 82%)",
        reversed: "linear-gradient(90deg, #6966FE 0%, #1DA9E6 82%)",
        hover: "linear-gradient(90deg, #2A6DBD 1%, #4240B3 98%)",
        slanted: "linear-gradient(72deg, #1DA9E6 0%, #6966FE 82%)",
        slantedLoop: "linear-gradient(72deg, #1DA9E6 0%, #6966FE 50%, #1DA9E6 100%)",
        slantedLoopBorder: "linear-gradient(72deg, #1DA9E6 0%, #6966FE 20%, #bf92ff 50%, #6966FE 80%, #1DA9E6 100%)",
        slantedReversed: "linear-gradient(72deg, #6966FE 0%, #1DA9E6 82%)",
        slantedLight: "linear-gradient(72deg, #EFF5FD 0%, #EEEEFE 79%)",
        slantedMedium: "linear-gradient(72deg, #e2edfb 0%, #e0e0fd 79%)",
        slantedDark: "linear-gradient(72deg, #040348 0%, #05045d 100%)",
        secondary: "linear-gradient(72deg, #1D3BE6 23.03%, #05B2A6 47.35%, #71DEFF 73.31%)",
        secondaryLoop: "linear-gradient(72deg, #1D3BE6 0%, #05B2A6 35%, #71DEFF 65%, #1D3BE6 100%)"
    },
    dark1: "#F5F5F5",
    dark2: "#E9ECEF",
    dark3: "#C4C9CD",
    dark4: "#6F7780",
    dark5: "#495057",
    dark6: "#343A40",
    dark2Text: "#9B9A9A",
    light1: "#FFFFFF",
    light2: "#F6F8FF",
    light3: "#EFF5FD",
    microsoftTeams: "#4B59CD",
    focusArea: {
        authority: "#E1FCFF",
        listening: "#FFF0C9",
        presence: "#D0FBEC",
        usage: "#FBDAC2"
    },
    coldCall: "#C6DBFA",
    warmCall: "#FAD8B8",
    redNotification: "#ED3E33",
    // Error/Success/Warning
    redError: "#CD4631",
    redErrorDark: "#a43826",
    redErrorLight: "#FFEAE9",
    greenSuccess: "#0FA16D",
    greenSuccessDark: "#0b6e47",
    greenSuccessLight: "#E3F8E2",
    // Used for attention grabbers i.e. SAVE 40%
    greenWarning: "#47BF40",
    greenHighlightShadow: "#2ECC51",
    yellowWarning: "#FFCA42",
    yellowWarningDark: "#E99923",
    yellowWarningLight: "#fffaef",
    orangeWarningDark: "#B8520B",
    orangeWarningLight: "#FBDAC2",
    orangeWarning: "#FFEDDF",
    orangeLight: "#FFEFE1",
    orangeDark: "#A0530C",
    muiGrey: "#0000001f",
    muiGreyText: "#A2A2A2",
    poodli: {
        red: "#AD460B",
        redLight: "#FFF8F4",
        green: "#09593D",
        greenLight: "#F5FFFC",
        greenContrast: "#E5FEF3",
        yellow: "#FFF1AA",
        yellowContrast: "#FEF2D7",
        yellowLight: "#FFFFE9",
        gold: "#9C9557"
    },
    landingPageHeroLight: "#F7FDFF",
    black: "#000000",
    pink: "#FF70B3"
};
// Define specific toastmasters colors
var toastmasterGradient = "linear-gradient(160deg, #004165 0%, #004165 29%, #6966FE 74%, #1DA9E6 100%)";
var TOASTMASTERS_COLORS = {
    primary: "#016094",
    navTextColor: "#FFFFFF",
    bannerLinkColor: "#FFFFFF",
    // for TMI, there is no light and dark
    navBackgroundLight: toastmasterGradient,
    navBackgroundDark: toastmasterGradient
};
// spread toastmasters color keys to default yoodli colors
// so i can access this key regardless of hte current site
Y_COLORS = _object_spread_props(_object_spread({}, Y_COLORS), {
    navTextColor: Y_COLORS.purple3,
    bannerLinkColor: Y_COLORS.purple2,
    navBackgroundLight: Y_COLORS.light1,
    navBackgroundDark: Y_COLORS.light2
});
export var Y_SHADOWS = {
    box_shadow_1: "1px 2px 5px rgba(33, 37, 41, 0.16)",
    box_shadow_2: "1px 2px 5px #ffffff75",
    box_shadow_3: "0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19)",
    box_shadow_4: "-2px -2px 3px rgba(33, 37, 41, 0.3)",
    box_shadow_5: "0px 8px 32px 0px rgba(0, 0, 0, 0.60)",
    box_shadow_6: "1px 2px 8px 2px rgba(33, 37, 41, 0.16)",
    box_shadow_7: "0px 4px 32px 0px rgba(0, 0, 0, 0.25)",
    // from jacobs ambassador page
    box_shadow_8: "0px 7px 16px 0px rgba(4, 3, 72, 0.30)",
    box_shadow_8_dark: "0px 7px 16px 0px rgba(4, 3, 72, 0.50)",
    box_shadow_9: "1px 0px 10px rgba(33, 37, 41, 0.16)",
    box_shadow_temp_hover: "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
    // 8 = upwards shadow for footers
    upShadow1: "0px -2px 8px 0px rgba(151, 151, 151, 0.25)",
    upShadow2: "0px -4px 8px 0px rgba(4, 3, 72, 0.25)",
    upShadowLine: "0px -1px 4px 0px rgba(0, 0, 0, 0.08)",
    // 9 = downwards shadow for headers
    downShadow1: "0px 2px 8px 0px rgba(151, 151, 151, 0.25)",
    box_shadow_inset: "inset 0px 0px 6px #D9D9D9",
    dark_elevation: "0px 1px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)",
    chart_type_selector: "0px 2.385px 2.385px 0px rgba(0, 0, 0, 0.25)",
    date_picker_popover: "0px 16px 32px 0px rgba(23, 37, 76, 0.12)"
};
var isToastmasters = function() {
    var _window_location, _window;
    return typeof window !== "undefined" && ((_window = window) === null || _window === void 0 ? void 0 : (_window_location = _window.location) === null || _window_location === void 0 ? void 0 : _window_location.hostname.split(".")[0]) === "toastmasters";
};
export function getDynamicColor(color) {
    var opacity = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    try {
        var colorArray = color.split(".");
        var colorMap = fromJS(Y_COLORS);
        var hexCode = colorMap.getIn(colorArray);
        if (isToastmasters()) {
            if (TOASTMASTERS_COLORS[color]) {
                hexCode = TOASTMASTERS_COLORS[color];
            }
        }
        if (opacity !== 1) {
            hexCode = chroma(hexCode).alpha(opacity).hex();
        }
        if (!hexCode) {
            throw new Error('Color "'.concat(color, '" not found.'));
        }
        return hexCode;
    } catch (er) {
        if (!er.message.includes("window is not defined")) {
            console.log("Error getting dynamic color: ".concat(er));
        }
        return "#000000";
    }
}
export var DEMEANOR_COLORS = chroma.scale([
    getDynamicColor("primary", 0.3),
    getDynamicColor("secondary", 0.2)
]).mode("lch").colors(10);
export var getDemeanorColor = function() {
    var demeanor = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "default";
    var hash = 0;
    for(var i = 0; i < demeanor.length; i++){
        var char = demeanor.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    // Convert to 32bit integer
    var index = Math.abs(hash) % DEMEANOR_COLORS.length;
    return DEMEANOR_COLORS[index];
};
