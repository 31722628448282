function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { isMobile } from "react-device-detect";
// Components
import { grey as muiGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
// Utils
import { Y_SHADOWS, getDynamicColor } from "../utils/Colors";
export var ADMIN_HEADER_HEIGHT = 80;
export var WIGGLE_ANIMATION = {
    animation: "wiggle 1s ease-in-out infinite",
    "@keyframes wiggle": {
        "0%": {
            transform: "rotate(4deg)"
        },
        "25%": {
            transform: "rotate(-4deg)"
        },
        "50%": {
            transform: "rotate(8deg)"
        },
        "75%": {
            transform: "rotate(-2deg)"
        },
        "100%": {
            transform: "rotate(0deg)"
        }
    }
};
export var CONTINUOUS_WIGGLE = {
    animation: "wiggle 0.5s linear infinite",
    "@keyframes wiggle": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "25%": {
            transform: "rotate(-5deg)"
        },
        "50%": {
            transform: "rotate(0deg)"
        },
        "75%": {
            transform: "rotate(5deg)"
        },
        "100%": {
            transform: "rotate(0deg)"
        }
    }
};
export var DEFAULT_MUI_DRAWER_TRANSITION = "300ms cubic-bezier(0, 0, 0.2, 1) 0ms";
/**
 * MUI theme palette mapping
 */ var MUI_PALETTE = {
    primary: {
        main: getDynamicColor("primary"),
        dark: getDynamicColor("purple3"),
        light: getDynamicColor("purple1"),
        contrastText: getDynamicColor("light1")
    },
    secondary: {
        main: getDynamicColor("secondary"),
        light: getDynamicColor("secondary"),
        dark: getDynamicColor("secondaryHover"),
        contrastText: getDynamicColor("light1")
    },
    success: {
        main: getDynamicColor("greenSuccess"),
        dark: getDynamicColor("greenSuccess"),
        light: getDynamicColor("greenSuccess"),
        contrastText: getDynamicColor("light1")
    },
    error: {
        main: getDynamicColor("redError"),
        dark: getDynamicColor("redError"),
        light: getDynamicColor("redError"),
        contrastText: getDynamicColor("light1")
    },
    info: {
        main: getDynamicColor("dark5"),
        dark: getDynamicColor("dark6"),
        light: getDynamicColor("dark4"),
        contrastText: "black"
    },
    grey: _object_spread_props(_object_spread({}, muiGrey), {
        600: getDynamicColor("dark6"),
        500: getDynamicColor("dark5"),
        400: getDynamicColor("dark4"),
        300: getDynamicColor("dark3"),
        200: getDynamicColor("dark2"),
        100: getDynamicColor("dark1"),
        50: getDynamicColor("light1")
    })
};
// View the default MUI theme here https://mui.com/customization/default-theme/#main-content
// to see what values are available to change and what their default values are
// Update the Button's variant prop options
export var defaultHeaderSx = {
    position: "relative",
    zIndex: 20,
    minHeight: ADMIN_HEADER_HEIGHT,
    background: getDynamicColor("purple3")
};
var defaultButtonStyles = {
    boxShadow: "none",
    width: "fit-content",
    minWidth: "unset",
    borderRadius: "100px",
    fontWeight: 700,
    lineHeight: 1.3,
    padding: "0.5em 1em",
    transition: "all 0.2s ease-in-out",
    fontFamily: '"poppins", "Inter", "Helvetica Neue", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: 16,
    "&:hover": {
        filter: "none"
    }
};
var customButtonStyles = _object_spread_props(_object_spread({}, defaultButtonStyles), {
    padding: "unset",
    margin: "unset",
    fontWeight: "initial",
    lineHeight: "initial",
    borderRadius: 0,
    width: "unset"
});
var textButtonStyles = _object_spread_props(_object_spread({}, defaultButtonStyles), {
    color: getDynamicColor("primary"),
    backgroundColor: "transparent",
    "&:hover": _object_spread_props(_object_spread({}, defaultButtonStyles["&:hover"]), {
        color: getDynamicColor("primaryHover"),
        backgroundColor: "transparent"
    }),
    "&:disabled": {
        backgroundColor: "transparent",
        color: getDynamicColor("dark3")
    }
});
var containedButtonStyles = _object_spread_props(_object_spread({}, defaultButtonStyles), {
    color: getDynamicColor("light1"),
    backgroundColor: getDynamicColor("primary"),
    "&:hover": _object_spread_props(_object_spread({}, defaultButtonStyles["&:hover"]), {
        backgroundColor: getDynamicColor("primaryHover")
    })
});
var outlinedButtonStyles = _object_spread_props(_object_spread({}, defaultButtonStyles), {
    backgroundColor: "transparent",
    border: "2px solid ".concat(getDynamicColor("primary")),
    color: getDynamicColor("primary"),
    borderWidth: "2px",
    "&:hover": _object_spread_props(_object_spread({}, defaultButtonStyles["&:hover"]), {
        borderWidth: "2px",
        backgroundColor: getDynamicColor("light2")
    }),
    "&:disabled": {
        borderWidth: 2
    }
});
var gradientButtonStyles = _object_spread_props(_object_spread({}, defaultButtonStyles, containedButtonStyles), {
    background: getDynamicColor("gradient.default"),
    zIndex: 1,
    "&::before": {
        borderRadius: "inherit",
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        fontSize: "inherit",
        width: "100%",
        height: "100%",
        background: "linear-gradient(\n      rgba(0, 0, 0, 0.25),\n      rgba(0, 0, 0, 0.25)\n    ), ".concat(getDynamicColor("gradient.default")),
        opacity: 0,
        zIndex: -1,
        transition: "all 0.2s ease-in-out"
    },
    "&:hover::before": {
        opacity: 1
    },
    "&:disabled": {
        background: getDynamicColor("dark2"),
        color: getDynamicColor("dark4")
    }
});
var smallButtonStyles = _object_spread_props(_object_spread({}, defaultButtonStyles), {
    padding: "4px 8px",
    fontWeight: 600
});
var darkButtonStyles = {
    backgroundColor: getDynamicColor("purple3"),
    "&:hover": {
        backgroundColor: getDynamicColor("purple3"),
        filter: "brightness(1.75)"
    }
};
var theme = createTheme({
    typography: {
        fontFamily: '"Poppins", "Inter", sans-serif, "Helvetica Neue", "Apple Color Emoji",\n    "Segoe UI Emoji", "Segoe UI Symbol"',
        h1: {
            fontWeight: 700,
            fontFamily: '"Poppins", system-ui, -apple-system, sans-serif'
        }
    },
    palette: _object_spread({}, MUI_PALETTE),
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            sm2: 640,
            md: 768,
            md2: 850,
            md3: 920,
            lg: 992,
            lg2: 1024,
            xl: 1200,
            xxl: 1500,
            xxxl: 2000
        }
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&:focus-visible": {
                        outline: "2px solid ".concat(getDynamicColor("primary")),
                        outlineOffset: "2px",
                        borderRadius: "4px",
                        background: "transparent",
                        zIndex: 9999
                    }
                }
            }
        },
        MuiCssBaseline: {
            // reset h1-h6 styles
            styleOverrides: '\n        \n        ht, h2, h3, h4, h5, h6 {\n          font-size: unset;\n          font-weight: unset;\n        }\n\n        h1 {\n          font-family: "Poppins";\n          font-weight: 700;\n        }\n          \n        .seo {\n          font-weight: inherit\n        },\n        .tiptap {\n          padding: 10px 20px;\n          overflow-y: auto;\n          height: 100%;\n          border-radius: 8px;\n          \n          h1,\n          h2,\n          h3,\n          h4 {\n            line-height: 1.1;\n            font-weight: revert;\n            font-size: revert;\n          }\n\n          p.is-editor-empty:first-of-type::before {\n            color: '.concat(getDynamicColor("dark4"), ";\n            content: attr(data-placeholder);\n            pointer-events: none;\n            height: 0;\n            float: left;\n          }\n        }\n      ")
        },
        MuiButton: {
            variants: [
                {
                    props: {
                        size: "xlarge"
                    },
                    style: {
                        padding: "12px 24px !important"
                    }
                },
                {
                    props: {
                        variant: "text",
                        disableRipple: true
                    },
                    style: _object_spread({}, textButtonStyles)
                },
                {
                    props: {
                        variant: "text_small"
                    },
                    style: _object_spread({}, textButtonStyles, smallButtonStyles)
                },
                {
                    props: {
                        variant: "custom"
                    },
                    style: _object_spread({}, customButtonStyles)
                },
                {
                    props: {
                        variant: "contained"
                    },
                    style: _object_spread({}, containedButtonStyles)
                },
                {
                    props: {
                        variant: "contained_dark"
                    },
                    style: _object_spread({}, containedButtonStyles, darkButtonStyles)
                },
                {
                    props: {
                        variant: "contained_small_dark"
                    },
                    style: _object_spread({}, containedButtonStyles, smallButtonStyles, darkButtonStyles)
                },
                {
                    props: {
                        variant: "contained_small"
                    },
                    style: _object_spread({}, containedButtonStyles, smallButtonStyles)
                },
                {
                    props: {
                        variant: "gradient"
                    },
                    style: _object_spread({}, gradientButtonStyles)
                },
                {
                    props: {
                        variant: "gradient_small"
                    },
                    style: _object_spread({}, gradientButtonStyles, smallButtonStyles)
                },
                {
                    props: {
                        variant: "outlined"
                    },
                    style: _object_spread({}, outlinedButtonStyles)
                },
                {
                    props: {
                        variant: "outlined_small"
                    },
                    style: _object_spread({}, outlinedButtonStyles, smallButtonStyles)
                }
            ],
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: _object_spread(_object_spread_props(_object_spread({}, defaultButtonStyles), {
                    textTransform: "none",
                    "&:focus-visible": {
                        outline: "2px solid ".concat(getDynamicColor("primary")),
                        outlineOffset: "2px"
                    }
                }), isMobile ? {
                    "&:hover::before": {
                        all: "unset"
                    }
                } : {})
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    fieldset: {
                        borderRadius: "4px"
                    },
                    "& ::placeholder": {
                        fontSize: "inherit"
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none"
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "poppins"
                    },
                    style: {
                        fontFamily: '"Poppins", sans-serif, "Helvetica Neue", "Apple Color Emoji",\n            "Segoe UI Emoji", "Segoe UI Symbol"'
                    }
                }
            ],
            styleOverrides: {
                root: {
                    letterSpacing: "0.17px",
                    fontSize: "inherit",
                    fontFamily: "inherit",
                    fontWeight: "inherit",
                    color: "inherit"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:focus-visible": {
                        outline: "2px solid ".concat(getDynamicColor("primary")),
                        outlineOffset: "2px"
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: Y_SHADOWS.box_shadow_1
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        }
    }
});
export default theme;
