function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import * as rt from "runtypes";
import { filterToIntegers } from "lib-fullstack/utils/helperFunctions";
/**
 * Create a new Runtype that decorates input Runtype as Optional(Union(T, Null)).
 */ export function NullableOptional(runtype) {
    return rt.Optional(rt.Union(runtype, rt.Null));
}
/**
 * Utility function to create a new Runtype from input Runtype
 * where all top level fields are made as optional. (i.e. not recursive)
 *
 * Note: there are two similar functions, but those are not the same.
 * - Partial<T> of Typescript: output is pure Typescript type, not Runtype type.
 * - Partial<T> of Runtype: input is Type script type, not Runtype type.
 *
 * @param runtype Source Runtype type
 * @returns A new Runtype type
 */ export function RTPartial(runtype) {
    var fields = runtype.fields;
    var partialFields = {};
    for(var key in fields){
        partialFields[key] = rt.Optional(fields[key]);
    }
    return rt.Record(partialFields);
}
/**
 * Create a Runtype from a Typescript string enum.
 * It is basically https://github.com/runtypes/runtypes/issues/66#issuecomment-788129292
 * and has an additional getStringLiterals function for auto Python types generation.
 * Note that Static<> of this Runtype becomes the original Typescript enum type.
 */ export var RTStringEnum = function(stringEnum) {
    var values = Object.values(stringEnum);
    var isEnumValue = function(input) {
        return values.includes(input);
    };
    var errorMessage = function(input) {
        return "Failed constraint check. Expected one of ".concat(JSON.stringify(values), ", but received ").concat(JSON.stringify(input));
    };
    var runtype = rt.Unknown.withConstraint(// eslint-disable-next-line @typescript-eslint/no-explicit-any
    function(object) {
        return isEnumValue(object) || errorMessage(object);
    });
    // @ts-expect-error
    // Ideally, this would be strongly typed by extending Constraint<A, T, K>.
    // However, it is not trivial because how withConstraint() is written.
    // Because this is used only by a specific purpose of Python type generation,
    // this dirty Javascript injection is used as a compromise.
    runtype.getStringLiterals = function() {
        return values;
    };
    return runtype;
};
/**
 * Create a Runtype from Typescript string enum,
 * specifically for Dictionary key type.
 * Dictionary key type can be string or number (or symbol) only.
 * RTStringEnum causes a type error in this case.
 */ export var RTStringEnumKey = function(stringEnum) {
    var _rt;
    var values = Object.values(stringEnum);
    var literals = values.map(rt.Literal);
    return (_rt = rt).Union.apply(_rt, [
        literals[0]
    ].concat(_to_consumable_array(literals.slice(1))));
};
/**
 * Create Runtype from a Typescript number enum.
 * This is a simpler version than RTStringEnum where this becomes
 * just number type when it's converted to a static type.
 * It is OK for now because it's used by only one place so far.
 */ export var RTNumericEnum = function(numericEnum) {
    var _rt;
    // when parsing an int enum, strip out the string keys
    var args = filterToIntegers(Object.keys(numericEnum));
    var literals = args.map(rt.Literal);
    return (_rt = rt).Union.apply(_rt, [
        literals[0]
    ].concat(_to_consumable_array(literals.slice(1))));
};
